const _DEFAULT = "1rem"
const _xs = "0.8125rem"
const _sm = "0.875rem"
const _base = "1rem"
const _lg = "1.125rem"
const _xl = "1.25rem"
const _2xl = "1.5rem"
const _3xl = "2rem"
const _4xl = "2.5rem"
const _5xl = "3rem"
const _6xl = "4rem"
const config = { "DEFAULT": _DEFAULT, "xs": _xs, "sm": _sm, "base": _base, "lg": _lg, "xl": _xl, "2xl": _2xl, "3xl": _3xl, "4xl": _4xl, "5xl": _5xl, "6xl": _6xl,  }
export { config as default, _DEFAULT, _xs, _sm, _base, _lg, _xl, _2xl, _3xl, _4xl, _5xl, _6xl }