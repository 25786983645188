import validate from "/root/front/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_encoding@0.1.13_eslint@8.57.0_ioredis@_uhqxhrb2bz2tw2selvi2s4rmkm/node_modules/nuxt/dist/pages/runtime/validate.js";
import band_45select_45global from "/root/front/middleware/bandSelect.global.ts";
import reset_45draft_45global from "/root/front/middleware/resetDraft.global.ts";
import route_45trailing_45slash_45global from "/root/front/middleware/routeTrailingSlash.global.ts";
import utm_45global from "/root/front/middleware/utm.global.ts";
export const globalMiddleware = [
  validate,
  band_45select_45global,
  reset_45draft_45global,
  route_45trailing_45slash_45global,
  utm_45global
]
export const namedMiddleware = {
  "draft-id": () => import("/root/front/middleware/draftId.ts"),
  "draft-influencers": () => import("/root/front/middleware/draftInfluencers.ts"),
  "draft-shared-music": () => import("/root/front/middleware/draftSharedMusic.ts"),
  "draft-step": () => import("/root/front/middleware/draftStep.ts"),
  "get-cart-and-favorites": () => import("/root/front/middleware/getCartAndFavorites.ts"),
  "influencer-dashboard": () => import("/root/front/middleware/influencerDashboard.ts"),
  "is-agency": () => import("/root/front/middleware/isAgency.ts"),
  "is-band": () => import("/root/front/middleware/isBand.ts"),
  "is-influencer": () => import("/root/front/middleware/isInfluencer.ts"),
  "is-logged-in": () => import("/root/front/middleware/isLoggedIn.ts"),
  "onboarding-flow": () => import("/root/front/middleware/onboardingFlow.ts"),
  "progressive-promos-check": () => import("/root/front/middleware/progressivePromosCheck.ts"),
  "redirect-missing-id-param": () => import("/root/front/middleware/redirectMissingIdParam.ts"),
  "tag-fetch": () => import("/root/front/middleware/tagFetch.ts")
}