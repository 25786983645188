export const availableLanguages = ['fr', 'en-US', 'en-GB', 'de'] as const
const addedExtendedLanguages = ['pt', 'it', 'es'] as const
export const availableExtendedLanguages = [
  ...addedExtendedLanguages,
  ...availableLanguages,
]

/**
 * Not to be confused with locale! A locale feeds into the vue i18n instance but a lang doesn't always match
 * Rule of thumb: Language is for the user, locale is for the text.
 */
export type Language = (typeof availableLanguages)[number]

/**
 * F.A.Q.
 * - What the fuck are ExtendedLangs ? :think:
 * These are langs we don't officialy support as an i18n target
 * But we still use them here and there to generate targeted content.
 */
export type ExtendedLanguage = (typeof availableExtendedLanguages)[number]
