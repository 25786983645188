import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { useUserStore } from './user'

import { provideGetCurrentUserFamily } from '~/api-core/Family/index'

import type { Locale } from '~/types/locale'
import type { UserAccount } from '~/types/userAccount'

export interface RootState {
  accounts: UserAccount[]
  locales: Locale[]
}

export const useRootStore = defineStore('root', {
  state: (): RootState => ({
    accounts: [],
    locales: ['en', 'fr'],
  }),
  actions: {
    REMOVE_ACCOUNT_BY_ID(accountId: number) {
      const index = this.accounts.findIndex((e) => e.pk === accountId)
      if (index > 0) this.accounts.splice(index, 1)
    },
    SET_ACCOUNTS(accounts: UserAccount[]) {
      this.accounts = accounts
    },
    async ACCOUNTS_LOAD() {
      const userStore = useUserStore()

      if (!userStore.IS_LOGGED_IN) return

      const getFamilyAccounts = provideGetCurrentUserFamily($coreFetch)
      const accounts = await getFamilyAccounts().catch(() => [])

      this.SET_ACCOUNTS(accounts)
    },
    ADD_NEW_ACCOUNT(account: UserAccount) {
      if (this.accounts.find((e) => e.pk === account.pk)) return

      this.SET_ACCOUNTS([...this.accounts, account])
    },
    USER_LOAD() {
      return useUserStore().LOAD()
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useRootStore, import.meta.hot))
