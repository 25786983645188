import { useUserStore } from '~/stores/user'

import { providePatchUserEmailLocale } from '~/api-core/Account/AuthUser'
import { providePatchAccountParameter } from '~/api-core/Account/Parameter'

import { LocaleDefintions } from '~/enums/LocaleDefinitions'
import { availableLanguages } from '~/types/language'

import type Tag from '~/entities/tag'
import type { Language } from '~/types/language'
import type { Locale } from '~/types/locale'

export const languageCookieKey = 'groover-user-selected-language'

export function getLocaleFromLanguage(lang: Language): Locale {
  switch (lang) {
    case 'de':
      return 'de'
    case 'en-GB':
      return 'en'
    case 'en-US':
      return 'en'
    case 'fr':
      return 'fr'
    default:
      return 'en'
  }
}

export function extrapolateLanguageFromLocale(locale: Locale): Language {
  switch (locale) {
    case 'de':
      return 'de'
    case 'fr':
      return 'fr'
    case 'en':
    default:
      return 'en-US'
  }
}

export function stringIsLanguage(
  candidate: string | null | undefined,
): candidate is Language {
  if (typeof candidate !== 'string') return false
  return availableLanguages
    .map((e) => e.toLowerCase())
    .includes(candidate.toLowerCase() as Language)
}

export function stringIsLocale(
  candidate: string | null | undefined,
): candidate is Locale {
  if (typeof candidate !== 'string') return false
  return LocaleDefintions.map((e) => e.code.toLowerCase()).includes(
    candidate.toLowerCase() as Locale,
  )
}

export function getLanguageFromTagCountryName(tagName: Tag['name']): Language {
  switch (tagName) {
    case 'france':
      return 'fr'
    case 'united-kingdom':
      return 'en-GB'
    case 'united-states':
      return 'en-US'
    case 'germany':
      return 'de'
    default:
      return 'en-US'
  }
}

function getLocaleStorageKeyForUserId(userId: number) {
  return `groover-user-${userId}-preferred-language`
}

/**
 * Exposes a few utility function to manipulate the user language and defer the app local from it.
 */
export function useLanguage() {
  const {
    $i18n: { setLocale, locale },
  } = useNuxtApp()
  const {
    IS_LOGGED_IN,
    id: USER_ID,
    lang: USER_LOCALE,
  } = storeToRefs(useUserStore())
  const { SET: SET_USER } = useUserStore()
  const selectedLanguageCookie = useCookie(languageCookieKey, {
    watch: true,
  })
  const switchLocalePath = useSwitchLocalePath()
  const i18nRedirectedCookie = useCookie('i18n_redirected', {
    watch: true,
  })

  /**
   * SSR friendly, user agnostic utility to retrieve the current selected language from the user
   *
   * @defaultValue 'en-US'
   * @returns the currently used language.
   */
  function getCurrentLanguage(): Language {
    const lastSelectedLanguageFromCookie = selectedLanguageCookie.value

    if (IS_LOGGED_IN.value) {
      if (!import.meta.client)
        return extrapolateLanguageFromLocale(USER_LOCALE.value)

      const storeKey = getLocaleStorageKeyForUserId(USER_ID.value)
      const potencialMatch = window.localStorage.getItem(storeKey)

      if (stringIsLanguage(potencialMatch)) return potencialMatch

      const nextBestValue = extrapolateLanguageFromLocale(USER_LOCALE.value)

      window.localStorage.setItem(storeKey, nextBestValue)
      return nextBestValue
    }

    if (stringIsLanguage(lastSelectedLanguageFromCookie))
      return lastSelectedLanguageFromCookie

    // Needed since it might hold a whole language with region but the i18n cookie will only hold locale
    if (stringIsLocale(i18nRedirectedCookie.value))
      return extrapolateLanguageFromLocale(i18nRedirectedCookie.value)

    return extrapolateLanguageFromLocale(locale.value as Locale)
  }

  const patchUserEmailLanguage = providePatchUserEmailLocale($coreFetch)
  const patchAccountParameter = providePatchAccountParameter($coreFetch)

  /**
   * SSR friendly, user agnostic utility to set the current language.
   * If logged in it will update the USER and it's preferences.
   * It will also extrapolate the nuxt-i18n locale from the choosen language.
   *
   * @returns the target URL to navigate to once the language was set
   */
  async function setCurrentLanguage(language: Language, email?: string) {
    if (IS_LOGGED_IN.value) {
      if (isDefined(email) && email.length) {
        await patchUserEmailLanguage(
          getLocaleFromLanguage(language),
          email,
        ).catch()
      }
      const lang = getLocaleFromLanguage(language)

      await patchAccountParameter({
        lang,
      })
      SET_USER({ lang })
    }

    if (!IS_LOGGED_IN.value) selectedLanguageCookie.value = language

    if (import.meta.client && IS_LOGGED_IN.value) {
      window.localStorage.setItem(
        getLocaleStorageKeyForUserId(USER_ID.value),
        language,
      )
    }

    await setLocale(getLocaleFromLanguage(language))
    return switchLocalePath(getLocaleFromLanguage(language))
  }

  function updateLanguageToCurrentState() {
    return setCurrentLanguage(getCurrentLanguage())
  }

  return {
    getCurrentLanguage,
    setCurrentLanguage,
    selectedLanguageCookie,
    updateLanguageToCurrentState,
  } as const
}
