import payloadToJson_f85A5nYSY0 from "/root/front/plugins/payloadToJson.ts";
import revive_payload_client_BQVUrIudIn from "/root/front/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_encoding@0.1.13_eslint@8.57.0_ioredis@_uhqxhrb2bz2tw2selvi2s4rmkm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_jJXUxXhqxD from "/root/front/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_encoding@0.1.13_eslint@8.57.0_ioredis@_uhqxhrb2bz2tw2selvi2s4rmkm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_TFYhIOTTmU from "/root/front/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_encoding@0.1.13_eslint@8.57.0_ioredis@_uhqxhrb2bz2tw2selvi2s4rmkm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_TjDL9xIYfs from "/root/front/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.19.0_vite@5.3.4_@types+node@20.14.11_sass@1.7_g66qpg3qj22kozsilnmpnnyzbm/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_k95kSDFb4I from "/root/front/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_encoding@0.1.13_eslint@8.57.0_ioredis@_uhqxhrb2bz2tw2selvi2s4rmkm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Yy6iABERcc from "/root/front/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_encoding@0.1.13_eslint@8.57.0_ioredis@_uhqxhrb2bz2tw2selvi2s4rmkm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_PJyL6tZPiy from "/root/front/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_encoding@0.1.13_eslint@8.57.0_ioredis@_uhqxhrb2bz2tw2selvi2s4rmkm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_kweDt6G8Gt from "/root/front/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.19.0_typescript@5.5.4_vue@3.4.33_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/root/front/.nuxt/components.plugin.mjs";
import prefetch_client_C2DQNRFKuH from "/root/front/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_encoding@0.1.13_eslint@8.57.0_ioredis@_uhqxhrb2bz2tw2selvi2s4rmkm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_CYlcDhgVje from "/root/front/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.4_rollup@4.19.0_vue@3.4.33_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_azRj2qruQO from "/root/front/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.19.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import polyfills_client_xo6BY28vLt from "/root/front/plugins/polyfills.client.ts";
import sentry_3AyO8nEfhE from "/root/front/plugins/sentry.ts";
import fetch_14DdzPiXUB from "/root/front/plugins/fetch.ts";
import pinia_48xmdi2HHl from "/root/front/plugins/pinia.ts";
import globalMixin_0vW6V1IUxN from "/root/front/plugins/globalMixin.ts";
import initCsrf_GudLqtAxMz from "/root/front/plugins/initCsrf.ts";
import segment_xRnKTSkpmU from "/root/front/plugins/segment.ts";
import segmentPageTracking_CDhc5tQIUy from "/root/front/plugins/segmentPageTracking.ts";
import closeCookiebotDialog_wHIJHWT4P3 from "/root/front/plugins/closeCookiebotDialog.ts";
import growthBook_T0cxi0ahK4 from "/root/front/plugins/growthBook.ts";
import stripe_6FZUdDHpIZ from "/root/front/plugins/stripe.ts";
import vueGtag_qdVBVzWkdt from "/root/front/plugins/vueGtag.ts";
export default [
  payloadToJson_f85A5nYSY0,
  revive_payload_client_BQVUrIudIn,
  unhead_jJXUxXhqxD,
  router_TFYhIOTTmU,
  _0_siteConfig_TjDL9xIYfs,
  payload_client_k95kSDFb4I,
  navigation_repaint_client_Yy6iABERcc,
  chunk_reload_client_PJyL6tZPiy,
  plugin_vue3_kweDt6G8Gt,
  components_plugin_KR1HBZs4kY,
  prefetch_client_C2DQNRFKuH,
  i18n_CYlcDhgVje,
  plugin_azRj2qruQO,
  polyfills_client_xo6BY28vLt,
  sentry_3AyO8nEfhE,
  fetch_14DdzPiXUB,
  pinia_48xmdi2HHl,
  globalMixin_0vW6V1IUxN,
  initCsrf_GudLqtAxMz,
  segment_xRnKTSkpmU,
  segmentPageTracking_CDhc5tQIUy,
  closeCookiebotDialog_wHIJHWT4P3,
  growthBook_T0cxi0ahK4,
  stripe_6FZUdDHpIZ,
  vueGtag_qdVBVzWkdt
]