import type { LocaleObject } from '@nuxtjs/i18n'

export const LocaleDefintions: LocaleObject[] = [
  {
    code: 'en',
    file: 'en.json',
    name: 'English',
    iso: 'en-US',
  },
  {
    code: 'fr',
    file: 'fr.json',
    name: 'Français',
    iso: 'fr-FR',
  },
  {
    code: 'de',
    file: 'de.json',
    name: 'Deutsch',
    iso: 'de-DE',
  },
]
